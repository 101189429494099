import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`3rd July 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext.current()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ServiceRequestContext.current()`}</inlineCode>{` have been added so you don't need to downcast `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{` to `}<inlineCode parentName="li">{`ClientRequestContext`}</inlineCode>{` or `}<inlineCode parentName="li">{`ServiceRequestContext`}</inlineCode>{`. It also makes sure the current context is client-side (or server-side), preventing a `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1869"
        }}>{`#1869`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1872"
        }}>{`#1872`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext cctx = ClientRequestContext.current();
ServiceRequestContext sctx = ServiceRequestContext.current();
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`currentOrNull()`}</inlineCode>{` static method has been added to `}<inlineCode parentName="li">{`(Client|Service)RequestContext`}</inlineCode>{`, which is similar to `}<inlineCode parentName="li">{`current()`}</inlineCode>{` but returning `}<inlineCode parentName="li">{`null`}</inlineCode>{` when there's no current context. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1872"
        }}>{`#1872`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
RequestContext ctx = RequestContext.mapCurrent(Function.identity(), null);
// After:
ServiceRequestContext sctx = ServiceRequestContext.currentOrNull();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now specify different `}<inlineCode parentName="li">{`AccessLogWriter`}</inlineCode>{`s for different services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1866"
        }}>{`#1866`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server = new ServerBuilder()
        .route().path("/svc1")
                .accessLogWriter(accessLogWriterForService1, true)
                .build(service1)
        .route().path("/svc2") // Will use the fallbackAccessLogWriter.
                .build(service2)
        .accessLogWriter(fallbackAccessLogWriter, true)
        .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`The metrics of the `}<inlineCode parentName="li">{`Executor`}</inlineCode>{` specified in `}<inlineCode parentName="li">{`ServerBuilder.blockingTaskExecutor()`}</inlineCode>{` will be exposed under `}<inlineCode parentName="li">{`executor{name=armeriaBlockingTaskExecutor}`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1683"
        }}>{`#1683`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1841"
        }}>{`#1841`}</a></li>
      <li parentName="ul">{`Armeria client will send a DNS query via TCP when a DNS response is truncated due to UDP packet size limitation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1873"
        }}>{`#1873`}</a></li>
      <li parentName="ul">{`You can now specify health check request interval using a `}<inlineCode parentName="li">{`Backoff`}</inlineCode>{`, which means you can have more flexible delay strategy such as adding jitter. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1846"
        }}>{`#1846`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpHealthCheckedEndpointGroup group = new HttpHealthCheckedEndpointGroup(...)
        .retryBackoff(Backoff.fixed(10000).withJitter(0.3))
        .build();
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`By default, `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` will now add 20% jitter to the health check request interval.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A new module `}<inlineCode parentName="li">{`armeria-brave`}</inlineCode>{` has been added to replace the deprecated `}<inlineCode parentName="li">{`armeria-zipkin`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1840"
        }}>{`#1840`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The package name has been changed from `}<inlineCode parentName="li">{`com.linecorp.armeria.{common,client,server}.tracing`}</inlineCode>{` to `}<inlineCode parentName="li">{`com.linecorp.armeria.{common,client,server}.brave`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`HttpTracingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpTracingService`}</inlineCode>{` have been renamed to `}<inlineCode parentName="li">{`BraveClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`BraveService`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now retrieve the `}<inlineCode parentName="li">{`retrofit2.Invocation`}</inlineCode>{` object associated with the current Retrofit call via `}<inlineCode parentName="li">{`InvocationUtil.get(RequestLog)`}</inlineCode>{`, which can be useful when building a meter ID prefix. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1591"
        }}>{`#1591`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1845"
        }}>{`#1845`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyRetrofitMeterIdPrefixFunction implements MeterIdPrefixFunction {
    @Override
    public MeterIdPrefix apply(MeterRegistry registry, RequestLog log) {
        final Invocation invocation = InvocationUtil.getInvocation(log);
        final String service;
        final String method;
        if (invocation != null) {
            service = invocation.method().getDeclaringClass().getSimpleName();
            method = invocation.method().getName();
        } else {
            service = "unknown";
            method = log.method().name();
        }
        return new MeterIdPrefix("call", "service", service, "method", method);
    }
}
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`We also added `}<inlineCode parentName="li">{`RetrofitMeterIdPrefixFunction`}</inlineCode>{` which provides the sensible default `}<inlineCode parentName="li">{`MeterIdPrefixFunction`}</inlineCode>{` for Retrofit calls.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The content negotiation logic has been relaxed so that the server falls back to the service with less constraint when content negotiation fails. Previously, it was rejecting the request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1860"
        }}>{`#1860`}</a></li>
      <li parentName="ul">{`More exceptions are now wrapped by `}<inlineCode parentName="li">{`UnprocessedRequestException`}</inlineCode>{` to indicate whether the request can be safely retried without worrying about sending the request more than once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1653"
        }}>{`#1653`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1848"
        }}>{`#1848`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Any exceptions that occurred during a connection attempt.`}</li>
          <li parentName="ul">{`Any exceptions that occurred during acquiring an existing connection from a connection pool.`}</li>
          <li parentName="ul">{`We also made `}<inlineCode parentName="li">{`UnprocessedRequestException.getCause()`}</inlineCode>{` is non-null, so you do not need to worry about the case where there is no cause in `}<inlineCode parentName="li">{`UnprocessedRequestException`}</inlineCode>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Server will now allow specifying a `}<inlineCode parentName="li">{`@Default`}</inlineCode>{` annotation with `}<inlineCode parentName="li">{`null`}</inlineCode>{` value. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1858"
        }}>{`#1858`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1864"
        }}>{`#1864`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`DEBUG`}</inlineCode>{` level log message which occurs when a client sent an ill-formed `}<inlineCode parentName="li">{`Accept`}</inlineCode>{` header is now easier to understand. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1849"
        }}>{`#1849`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`THttpService`}</inlineCode>{` now handles `}<inlineCode parentName="li">{`HttpStatusException`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpResponseException`}</inlineCode>{` correctly, which means they will not be translated into a `}<inlineCode parentName="li">{`200 OK`}</inlineCode>{` response that contains a `}<inlineCode parentName="li">{`TApplicationException`}</inlineCode>{` anymore. For example, your Thrift clients will see `}<inlineCode parentName="li">{`503 Service Unavailable`}</inlineCode>{` response when your service throws an `}<inlineCode parentName="li">{`HttpStatusException.of(503)`}</inlineCode>{` rather than `}<inlineCode parentName="li">{`200 OK`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1839"
        }}>{`#1839`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1867"
        }}>{`#1867`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DocService`}</inlineCode>{` debug form will validate the endpoint path properly now. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1856"
        }}>{`#1856`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1857"
        }}>{`#1857`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextCurrentTraceContext`}</inlineCode>{` now calls `}<inlineCode parentName="li">{`decorateScope()`}</inlineCode>{` when necessary. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1840"
        }}>{`#1840`}</a></li>
      <li parentName="ul">{`Spring Web and WebFlux integration do not ignore the `}<inlineCode parentName="li">{`ssl.keyAlias`}</inlineCode>{` property anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1843"
        }}>{`#1843`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1865"
        }}>{`#1865`}</a></li>
      <li parentName="ul">{`You will not see an `}<inlineCode parentName="li">{`ERROR`}</inlineCode>{` log message when a client sends a `}<inlineCode parentName="li">{`HEAD`}</inlineCode>{` request to your Spring WebFlux endpoint. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1847"
        }}>{`#1847`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1859"
        }}>{`#1859`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HttpHealthCheckedEndpointGroupBuilder.retryInterval(Duration)`}</inlineCode>{` has been deprecated. Use `}<inlineCode parentName="li">{`retryBackoff(Backoff)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1846"
        }}>{`#1846`}</a></li>
      <li parentName="ul">{`Everything in `}<inlineCode parentName="li">{`armeria-zipkin`}</inlineCode>{` has been deprecated. Use `}<inlineCode parentName="li">{`armeria-brave`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1840"
        }}>{`#1840`}</a></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.6.3 -> 5.6.6`}</li>
      <li parentName="ul">{`FastUtil 8.2.2 -> 8.2.3`}</li>
      <li parentName="ul">{`Hibernate Validator 6.0.16 -> 6.0.17`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`javax.annotation-api`}</inlineCode>{` -> `}<inlineCode parentName="li">{`jakarta.annotation-api`}</inlineCode>{` 1.3.4`}</li>
      <li parentName="ul">{`Jetty 9.4.18 -> 9.4.19`}</li>
      <li parentName="ul">{`Micrometer 1.1.4 -> 1.2.0`}</li>
      <li parentName="ul">{`Netty 4.1.34 -> 4.1.37`}
        <ul parentName="li">
          <li parentName="ul">{`TCNative BoringSSL 2.0.23 -> 2.0.25`}</li>
        </ul>
      </li>
      <li parentName="ul">{`protobuf-jackson 0.4.0 -> 1.0.0`}</li>
      <li parentName="ul">{`RxJava 2.2.9 -> 2.2.10`}</li>
      <li parentName="ul">{`Spring Boot 2.1.5 -> 2.1.6`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['Adanz', 'anuraaga', 'cj848', 'codefromthecrypt', 'hyeonjames', 'ikhoon', 'imasahiro', 'kojilin', 'minwoox', 'normanmaurer', 'southernkasaist', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      